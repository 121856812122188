import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://ncc.${config.devbase}`
config.prodURL = 'https://cms.noosacarclub.com.au'
config.siteURL = 'https://app.noosacarclub.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.sentryDsn = 'https://40c5b4cf08bf4598b0c796505a51190e@glitchtip.logger.jm1.au/21'

config.login.code_only = true
config.signUp.ask_date_of_birth = true
// config.racers.clientApiId = ''
// config.racers.eventManager = 0
config.profile.edit_date_of_birth = true
config.profile.edit_club_additional_member = true

export { config }
